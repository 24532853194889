import {useState} from 'preact/hooks';


export default function App() {
  const videosList = [
    {modulo:1, aula:'I', video:'1', id:'416498231', desc:'Comece a estudar música para tocar violão e guitarra'},
    {modulo:1, aula:'I', video:'2.1', id:'416477601', desc:'Aprenda a usar aplicativos para afinar violão e guitarra' },
    {modulo:1, aula:'I', video:'2.2', id:'416500725', desc:'Aprenda a usar o afinador eletrônico de violão e guitarra' },
    {modulo:1, aula:'I', video:'3', id:'416502355', desc:'Estudando leitura de tablatura' },
    {modulo:1, aula:'I', video:'4', id:'416484503', desc:'Estudando leitura de tablatura' },
    {modulo:1, aula:'II', video:'1', id:'417723183', desc:'Teoria da Aula 2' },
    {modulo:1, aula:'II', video:'2', id:'417730289', desc:'The Wall' },
    {modulo:1, aula:'II', video:'3', id:'417733673', desc:'A Pantera Cor-de-Rosa' },
    {modulo:1, aula:'II', video:'4', id:'419008303', desc:'Blues em Mi' },
    {modulo:1, aula:'III', video:'1', id:'423836844', desc:'Ensinando os Acordes' },
    {modulo:1, aula:'III', video:'2', id:'417761724', desc:'Blues em Lá' },
    {modulo:1, aula:'III', video:'3', id:'427222748', desc:'Os 8 primeiros acordes do violão e da guitarra' },
    {modulo:1, aula:'III', video:'4', id:'440085488', desc:'Aula sobre estudar o ritmo com o metrônomo' },
    {modulo:1, aula:'IV', video:'1', id:'445954027', desc:'Nesta aula começamos a montar um repertório a partir da música "Last Kiss" da banda "Pearl Jam"' },
  ]

  const [videos] = useState(videosList);
  console.log('teste');
  return (
    <div className='dflex flex-column' style={{minHeight:'100vh', width:'100%'}}>
      <div className=''>
        <div className='dflex center'>
            <img 
              className='land'
              objectFit='cover'
              src="../assets/eduardolucio.png" 
              alt="Eduardo Lucio Cena"  />
        </div>
        <div className='overLand' />
      </div>
      <div className='dflex center flex-column m-1'>
        
      <div className='emphasys'>Curso em vídeo-aulas de Violão e Guitarra</div>
        
        <div className=' dflex around w-100 m-1 p-1'>

            <div className='fill dflex flex-column center m-1'>
              <div className='icn-l m-1'><a target="_blank" href='tel:34991516465'><img className='icn-l' objectFit='cover' src='../assets/ligue.png' /></a></div>
              <div className='msg dflex center m-1'>(34) 99151-6465</div>
            </div>  

            <div className='fill dflex flex-column m-1'>
              <div className='dflex evenly'>
                <div className='icn-l m-1'><a target="_blank" href='https://www.instagram.com/eduardolmsouza'><img className='icn-l' objectFit='cover' src='../assets/insta.png' /></a></div>
                <div className='icn-l m-1'><a target="_blank" href='http://youtube.com/eduardolucioguitar'><img className='icn-l' objectFit='cover' src='../assets/youtube.png' /></a></div>
                <div className='icn-l m-1'><a target="_blank" href='https://www.facebook.com/ProfessordeguitarraEduardoLucio/'><img className='icn-l' objectFit='cover' src='../assets/fb.png' /></a></div>
              </div>            
              <div className='dflex center m-1'><div className='msg'>conecte-se</div></div>
            </div>

            <div className='fill dflex flex-column center m-1'>
              <div className='icn-l m-1'><a target="_blank" href='https://wa.me/5534991516465'><img className='icn-l' objectFit='cover' src='../assets/wsp.png' /></a></div>
              <div className='msg dflex center m-1'>envie mensagem</div>
            </div>

          </div>
          
      </div>

      <div className='dflex center flex-column fill bg-white color-black'>
        <div className='msg m-1'>Assista agora vídeos gratuitos e amostras do Curso</div>
        <iframe className='m-1' src="https://player.vimeo.com/video/417795423" width="100%" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
        <hr className='border-grey w-100' />
        <h1>CURSO EM VÍDEO AULAS</h1>
        <div className='emphasys'>MÓDULO I</div>
        {videos.map(v => (<div key={v.id} className='border-grey m-1 w-50 dflex flex-column center'>
            <div className='msg m-1'><strong>{'Aula ' + v.aula + ' - Vídeo ' + v.video}</strong></div>
            <div className='msg m-1 dflex center'>{v.desc}</div>
            <iframe className='m-1' src={"https://player.vimeo.com/video/" + v.id} width="50%" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
          </div>
        ))}
     
      </div>
    </div>
  )
}
